const genera = [
  {
    name: "Неизвестно",
    lat: "",
    species: []
  },
  {
    name: "Дуб",
    lat: "Quércus",
    species: ["черешчатый", "красный"]
  },
  {
    name: "Берёза",
    lat: "Bétula",
    species: [
      "повислая",
      "золотистая",
      "Эрмана (каменная)",
      "камчатская (японская)",
      "Крылова",
      "карликовая (Ерник)",
      "бумажная",
      // "поникающая, бородавчатая (плакучая)",
      "пушистая",
      // "вишнёвая"
    ]
  },
  {
    name: "Тополь",
    lat: "Pópulus",
    species: [
      "дрожащий (Осина)",
      "советский пирамидальный",
      "Осина пирамидальная свердловская",
      "лавролистный",
      "белый",
      "бальзамический",
      "берлинский",
      "канадский",
      "сероватый",
      "четконосный",
      "возобновляющийся",
      "московский",
      "черный (Осокорь)",
      "петровский",
      "сибирский",
      "Симона (китайский)",
      "душистый",
      "дельтовидный"
    ]
  },
  {
    name: "Липа",
    lat: "Tília",
    species: [
      "мелколистная (сердцелистная)",
      "европейская",
      "обыкновенная",
      "крупнолистная (широколистная)",
      // "американская"
    ]
  },
  {
    name: "Яблоня",
    lat: "Mālus",
    species: [
      "лесная",
      "Недзвецкого",
      "пурпурная",
      "ягодная",
      "домашняя",
      "маньчжурская",
      "ранняя",
      "торинго (Зибольда)",
      "сливолистная (Китайка)"
    ]
  },
  {
    name: "Клён",
    lat: "Ácer",
    species: [
      "приречный (Гиннала)",
      "татарский",
      "сахаристый",
      "бородатый",
      "полевой",
      "ясенелистный (американский)",
      "платановидный",
      "ложноплатановый (Явор)",
      "татарский (Неклен)"
    ]
  },
  {
    name: "Груша",
    lat: "Pýrus",
    species: ["уссурийская", "обыкновенная", "лесная"]
  },
  {
    name: "Слива",
    lat: "Prunus",
    species: ["домашняя"]
  },
  {
    name: "Рябина",
    lat: "Sórbus",
    species: [
      "обыкновенная",
      "садовая (домашняя)",
      "гибридная",
      "промежуточная",
      "бузинолистная"
    ]
  },
  {
    name: "Ясень",
    lat: "Fraxinus",
    species: [
      "пенсильванский",
      "американский",
      "обыкновенный",
      "ланцетнолисточковый",
      "маньчжурский"
    ]
  },
  {
    name: "Ирга",
    lat: "Amelánchier",
    species: [
      "обильноцветущая",
      "Ламарка (канадская)",
      "овальнолистная",
      "колосистая"
    ]
  },
  {
    name: "Сирень",
    lat: "Syrínga",
    species: ["обыкновенная", "венгерская"]
  },
  {
    name: "Лиственница",
    lat: "Lárix",
    species: ["европейская", "сибирская", "Гмелина (даурская)", "польская"]
  },
  {
    name: "Вяз",
    lat: "Úlmus",
    species: [
      "гладкий",
      "шершавый (Ильм горный)",
    ]
  },
  {
    name: "Ива",
    lat: "Sálix",
    species: [
      "белая (Ветла)",
      "ломкая",
      "Шверина",
      "остролистная (Верба)",
      "козья (Ракита)",
      "пепельная",
      "филиколистная",
      "пурпурная",
      "памяти Бажова",
      "уральская извилистая",
      "водопад",
      "розмаринолистная",
      "трехтычинковая",
      "корзиночная",
      "шерстистопобеговая",
      "пятитычинковая",
      "черничная",
      "красноватая",
      "лопарская",
      "Матсуды",
      "мирзинолистная (чернеющая)",
    ]
  },
  {
    name: "Сосна",
    lat: "Pínus",
    species: ["горная (Жереп)", "сибирская", "обыкновенная"]
  },
  {
    name: "Можжевельник",
    lat: "Juníperus",
    species: []
  },
  {
    name: "Ель",
    lat: "Pícea",
    species: [
      "европейская (обыкновенная)",
      "Энгельмана",
      "финская",
      "канадская",
      "сибирская",
      "колючая"
    ]
  },
  {
    name: "Черемуха",
    lat: "Padus",
    species: ["обыкновенная", "Маака", "поздняя", "виргинская"]
  },
  {
    name: "Туя",
    lat: "",
    species: ["западная"]
  },
  {
    name: "Пихта",
    lat: "",
    species: ["сибирская"]
  },
  {
    name: "Ольха",
    lat: "",
    species: ["черная (клейкая)", "серая"]
  },
  {
    name: "Лох",
    lat: "",
    species: ["узколистный", "серебристый (смешиваемый)"]
  },
  {
    name: "Облепиха",
    lat: "",
    species: ["жестеровидная (обыкновенная)"]
  },
  {
    name: "Орех",
    lat: "",
    species: ["маньчжурский"]
  },
  {
    name: "Абрикос",
    lat: "",
    species: ["маньчжурский"]
  },
  {
    name: "Вишня",
    lat: "",
    species: [
      "птичья (Черешня)",
      "кустарниковая (степная)",
      "пенсильванская",
      "войлочная",
      "обыкновенная (садовая)"
    ]
  },
  {
    name: "Лавр",
    lat: "",
    species: []
  },
  {
    name: "Боярышник",
    lat: "",
    species: ["желтоплодный", "кроваво-красный", "перистонадрезанный"]
  },
  {
    name: "Бархат",
    lat: "",
    species: ["амурский"]
  },
  {
    name: "Кедр",
    lat: "",
    species: []
  }
];

export default genera;

export const species = genera.reduce(
  (ar, el) => [
    ...ar,
    ...el.species.map(value => ({
      name: el.name + " " + value,
      value: el.name + " " + value,
      parent: el
    }))
  ],
  []
);
