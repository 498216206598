const generaBush = [
  {
    lat: "",
    name: "Микробиота",
    species: ["перекрестнопарная"]
  },
  {
    lat: "",
    name: "Можжевельник",
    species: ["казацкий", "горизонтальный", "скальный", "обыкновенный"]
  },
  {
    lat: "",
    name: "Сосна",
    species: ["горная"]
  },
  {
    lat: "",
    name: "Туя",
    species: ["западная"]
  },
  {
    lat: "",
    name: "Арония",
    species: ["Мичурина"]
  },
  {
    lat: "",
    name: "Барбарис",
    species: ["обыкновенный", "Тунберга", "оттавский"]
  },
  {
    lat: "",
    name: "Бересклет",
    species: ["европейский"]
  },
  {
    lat: "",
    name: "Боярышник",
    species: [
      "сибирский (кроваво-красный)",
      "полумягкий",
      "алтайский",
      "крупноколючковый",
      "перистонадрезанный"
    ]
  },
  {
    lat: "",
    name: "Гортензия",
    species: ["древовидная", "метельчатая"]
  },
  {
    lat: "",
    name: "Дерен",
    species: ["белый"]
  },
  {
    lat: "",
    name: "Ива",
    species: ["пурпурная"]
  },
  {
    lat: "",
    name: "Калина",
    species: ["обыкновенная", "Бульденеж"]
  },
  {
    lat: "",
    name: "Кизильник",
    species: ["блестящий", "черноплодный", "горизонтальный", "Даммера"]
  },
  {
    lat: "",
    name: "Лапчатка",
    species: ["кустарниковая"]
  },
  {
    lat: "",
    name: "Лох",
    species: ["серебристый"]
  },
  {
    lat: "",
    name: "Магония",
    species: ["падуболистная"]
  },
  {
    lat: "",
    name: "Мирикария",
    species: ["лисохвостниковая"]
  },
  {
    lat: "",
    name: "Пузыреплодник",
    species: ["калинолистный"]
  },
  {
    lat: "",
    name: "Роза",
    species: ["сизая", "бедренцоволистная (колючейшая)", "морщинистая"]
  },
  {
    lat: "",
    name: "Рябинник",
    species: ["рябинолистный"]
  },
  {
    lat: "",
    name: "Сирень",
    species: ["венгерская", "обыкновенная", "амурская", "Мейера"]
  },
  {
    lat: "",
    name: "Снежноягодник",
    species: ["белый", "Доренбоза"]
  },
  {
    lat: "",
    name: "Спирея",
    species: [
      "аргута (снежная)",
      "березолистная",
      "дубровколистная",
      "Бумальда",
      "серая",
      "японская",
      "иволистная",
      "Дугласа",
      "Вангутта",
      "зверобоелистная"
    ]
  },
  {
    lat: "",
    name: "Смородина",
    species: ["альпийская", "золотистая", "скандинавская"]
  },
  {
    lat: "",
    name: "Чубушник",
    species: ["венечный", "земляничный", "опушенный", "душистый"]
  },
  {
    lat: "",
    name: "Виноград",
    species: ["Девичий виноград"]
  },
  {
    lat: "",
    name: "Жимолость",
    species: ["каприфоль", "обыкновенная"]
  },
  {
    lat: "",
    name: "Клематис",
    species: ["мелкоцветковый"]
  },
  {
    lat: "",
    name: "Малина",
    species: ["обыкновенная"]
  },
  {
    lat: "",
    name: "Лещина",
    species: ["обыкновенная"]
  },
  {
    lat: "",
    name: "Миндаль",
    species: ["низкий"]
  },
  {
    lat: "",
    name: "Крушина",
    species: ["ломкая"]
  },
  {
    lat: "",
    name: "Бузина",
    species: ["сибирская"]
  },
  {
    lat: "",
    name: "Форзиция",
    species: ["свисающая"]
  },
  {
    lat: "",
    name: "Карагана",
    species: ["древовидная (желтая акация)"]
  }
];

export default generaBush;

export const species = generaBush.reduce(
  (ar, el) => [
    ...ar,
    ...el.species.map(value => ({
      lat: "",
      name: el.name + " " + value,
      value: el.name + " " + value,
      parent: el
    }))
  ],
  []
);
